var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"Index"}},[(
      ![
        'About',
        'Warehouse',
        'BagRecharge',
        'shareReward',
        'Tipspage',
      ].includes(_vm.$route.name)
    )?_c('MeanHeader'):_vm._e(),_c('div',{staticClass:"out-bj"},[_c('router-view',{attrs:{"id":"output"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showScrollTopBtn),expression:"showScrollTopBtn"}],staticClass:"scroll-top",on:{"click":_vm.scrollTopOnClick}}),_c('div',{attrs:{"id":"footer"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }