<template>
  <div id="NavHeader">
    <swiper></swiper>
    <div class="nav">
      <div class="Nav_Left">
        <div class="logo" @click="jump('/')">
          <img src="@/assets/images/NavHeader/logo.png" alt="" />
        </div>
        <div class="nav-box">
          <div
            class="item"
            @click="jump('/')"
            :class="{ active: this.$route.path == '/Home' }">
            <div class="nav-item-l">
              <span>首页</span>
              <i>HOME GAGE</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item gameClass"
            :class="{
              active:
                this.$route.path == '/classicsBox' ||
                this.$route.path == '/Lucky' ||
                this.$route.path == '/Battle' ||
                this.$route.path == '/classicsBox' ||
                this.$route.path == '/Roll',
            }">
            <div class="nav-item-l">
              <span>游戏中心</span>
              <i>HOME CENTER</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
            <div class="drop">
              <div class="drop-item" @click="jump('/classicsBox')">
                <img src="@/assets/images/NavHeader/openbox.png" alt="" />
                <div>经典盲盒</div>
              </div>
              <div class="drop-item" @click="jump('/Lucky')">
                <img src="@/assets/images/NavHeader/lucky.png" alt="" />
                <div>幸运饰品</div>
              </div>
              <div class="drop-item" @click="jump('/Battle')">
                <img src="@/assets/images/NavHeader/battle.png" alt="" />
                <div>虚拟对战</div>
              </div>
              <div class="drop-item" @click="jump('/Roll')">
                <img src="@/assets/images/NavHeader/rollicon.png" alt="" />
                <div>ROLL房</div>
              </div>
            </div>
          </div>
          <div
            class="item"
            @click="jump('/Mall')"
            :class="{
              active: this.$route.path == '/Mall',
              dropClass: this.$route.path == '/Mall',
            }">
            <div class="nav-item-l">
              <span>商城</span>
              <i>GAGE MALL</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="goabout"
            :class="{ active: this.$route.path == '/shareReward' }">
            <div class="nav-item-l">
              <span>分享</span>
              <i>SHARE</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="goWarehouse"
            :class="{ active: this.$route.path == '/Warehouse' }">
            <div class="nav-item-l">
              <span>背包</span>
              <i>DEPOT</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <div
            class="item"
            @click="gopay"
            :class="{ active: this.$route.path == '/BagRecharge' }">
            <div class="nav-item-l">
              <span>支付</span>
              <i>PAY</i>
            </div>
            <div class="nav-item-r none"></div>
            <i class="line"></i>
          </div>
          <!-- <div class="nav-pay-box">
            <div class="item-pay" @click="gopay">
              <span>支付</span>
            </div>
            <i></i>
            <div class="item-package" @click="goWarehouse">
              <span>背包</span>
            </div>
          </div> -->
        </div>
      </div>
      <div class="personal">
        <div class="Sign" v-show="this.IndividualsShow == false">
          <div class="Sign-item">
            <span @click="PostSignInShow">请登录</span>
            <span style="margin: 0 4px"> / </span>
            <span @click="PostRegisterInShow">注册</span>
          </div>
        </div>

        <div class="per" v-show="this.IndividualsShow == true">
          <div class="user" @click="jump('/User')">
            <img class="user-img" :src="user.avatar" alt="" />
            <div class="">
              <span style="font-size: 0.2rem">{{ user.name }}</span>
              <div
                class="d-flex align-items-center"
                style="font-size: 0.15rem; color: #ecb105; margin-top: 5px">
                <Cprice :price="user.bean" />
                <!-- <img style="width: 0.2rem;margin-right: 0.05rem;" src="@/assets/images/about/user/huobi.png" alt="">{{
                  user.bean }} -->
              </div>
              <div
                class="d-flex align-items-center"
                style="font-size: 0.15rem; color: #ecb105; margin-top: 5px">
                <!-- <Cint :price="user.integral" /> -->
                <img
                  style="width: 0.2rem; margin-right: 0.05rem"
                  src="@/assets/images/about/user/zhaunshi.png"
                  alt="" />
                <span style="font-size: 0.18rem; font-weight: 600">
                  {{ user.integral }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="SwiperBox-text"
      style="margin: 10px auto; width: 70%"
      v-show="noticeBarStatus">
      <!-- <img class="img" src="@/assets/images/NavHeader/11.png" alt="" /> -->
      <van-notice-bar
        class="notice-bar"
        color="#ccc"
        mode="closeable"
        scrollable
        @close="noticeBarStatus = false">
        <span style="color: #ecb105">{{ site_bulletin }}</span>
      </van-notice-bar>
    </div>
    <!-- <div class="NavHeader-bg"></div> -->
    <div class="XiangZi_Box" v-if="false">
      <div
        class="XiangZi_item"
        v-for="(item, index) in Box_Arr"
        :key="index"
        @click="ShowNeiRong(item)">
        <div class="pic">
          <img :src="item.intact_cover" alt="" />
        </div>
        <span>${{ item.game_bean }}</span>
        <p>{{ item.name }}</p>
      </div>
    </div>

    <!-- 登录弹框 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="SignInShow">
      <div class="Wrapper" v-if="SignInShow">
        <div class="out">
          <div class="close-box" @click="SignInQX">
            <img src="@/assets/images/login/close.png" alt="" />
          </div>
          <img
            src="@/assets/images/login/title.png"
            alt=""
            class="login-title" />
          <img src="@/assets/images/login/line.png" alt="" class="line" />
          <div class="DZ-box">
            <div class="active-login" :class="!PWCode ? 'active-login-ac' : ''">
              <span class="" style="cursor: pointer" @click="PWCode = true"
                >密码登录</span
              >
              <span class="" style="cursor: pointer" @click="PWCode = false"
                >验证码登录</span
              >
            </div>
            <el-form
              :model="SignInForm"
              status-icon
              ref="SignInForm"
              label-width="90px"
              class="demo-ruleForm">
              <el-form-item prop="mobile" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/phone.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    v-model="SignInForm.mobile"
                    placeholder="输入手机号"></el-input>
                </div>
              </el-form-item>
              <el-form-item v-if="PWCode" prop="password" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    type="password"
                    v-model="SignInForm.password"
                    placeholder="输入密码"></el-input>
                </div>
              </el-form-item>
              <el-form-item v-else label="" class="YZM-box" prop="verify">
                <div class="input-wrap code">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    v-model="SignInForm.verify"
                    placeholder="输入验证码"></el-input>
                </div>
                <div
                  class="YZM"
                  @click="GetYanZhengMa(2)"
                  v-show="VerificationShow == false">
                  发送验证码
                </div>
                <div class="DJS" v-show="VerificationShow == true">
                  <div class="XXX">已发送</div>
                  <van-count-down
                    :time="time"
                    class="CLR"
                    format="ss"
                    :auto-start="false"
                    @finish="finish"
                    ref="countDown" />
                </div>
              </el-form-item>
              <!-- <div class="remember_box">
                <el-checkbox v-model="checked">记住密码</el-checkbox>
                <p @click="GetChangePassword">忘记密码</p>
              </div> -->
              <div class="args">
                <div class="args-item">
                  <img
                    src="@/assets/images/login/check.png"
                    v-show="!argsStatus1"
                    alt=""
                    @click="argsStatus1 = true" />
                  <img
                    src="@/assets/images/login/checked.png"
                    v-show="argsStatus1"
                    alt=""
                    @click="argsStatus1 = false" />
                  我已阅读并同意《用户协议》和《隐私政策》
                </div>
                <div class="args-item">
                  <img
                    src="@/assets/images/login/check.png"
                    v-show="!argsStatus2"
                    alt=""
                    @click="argsStatus2 = true" />
                  <img
                    src="@/assets/images/login/checked.png"
                    v-show="argsStatus2"
                    alt=""
                    @click="argsStatus2 = false" />
                  确认已满18岁，盲盒娱乐理性消费，请勿上头
                </div>
              </div>
              <el-form-item>
                <div class="btn-box">
                  <div class="btn" @click="PostSignIn">登录</div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 注册弹框 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="RegisterShow">
      <div class="Wrapper">
        <div class="out">
          <div class="close-box" @click="SignInQX2">
            <img src="@/assets/images/login/close.png" alt="" />
          </div>
          <img
            src="@/assets/images/login/title.png"
            alt=""
            class="login-title" />
          <img src="@/assets/images/login/line.png" alt="" class="line" />
          <div class="DZ-box">
            <!-- <div class="active-login">
              <span class="" style="cursor: pointer" @click="PWCode = true">注册</span>
              <span class="" style="cursor: pointer" @click="PWCode = false">验证码登录</span>
            </div> -->
            <el-form
              :model="RegisterForm"
              status-icon
              ref="RegisterForm"
              label-width="90px"
              class="demo-ruleForm">
              <el-form-item prop="name" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    type="name"
                    v-model="RegisterForm.name"
                    placeholder="昵称"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="mobile" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/phone.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    v-model="RegisterForm.mobile"
                    placeholder="输入手机号"></el-input>
                </div>
              </el-form-item>
              <el-form-item label="" class="YZM-box" prop="verify">
                <div class="input-wrap code">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    v-model="RegisterForm.verify"
                    placeholder="输入验证码"></el-input>
                </div>
                <div
                  class="YZM"
                  @click="GetYanZhengMa2(1)"
                  v-show="VerificationShow == false">
                  发送验证码
                </div>
                <div class="DJS" v-show="VerificationShow == true">
                  <div class="XXX">已发送</div>
                  <van-count-down
                    :time="time"
                    class="CLR"
                    format="ss"
                    :auto-start="false"
                    @finish="finish"
                    ref="countDown" />
                </div>
              </el-form-item>
              <el-form-item prop="password" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    type="password"
                    v-model="RegisterForm.password"
                    placeholder="输入密码"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="password1" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    type="password"
                    v-model="RegisterForm.password1"
                    placeholder="再次输入密码"></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="invite" label="">
                <div class="input-wrap">
                  <img
                    src="@/assets/images/login/pass.png"
                    alt=""
                    class="phone-icon" />
                  <el-input
                    v-model="RegisterForm.invite"
                    placeholder="邀请码"></el-input>
                </div>
              </el-form-item>
              <div class="args">
                <div class="args-item">
                  <img
                    src="@/assets/images/login/check.png"
                    v-show="!XYchecked"
                    alt=""
                    @click="XYchecked = true" />
                  <img
                    src="@/assets/images/login/checked.png"
                    v-show="XYchecked"
                    alt=""
                    @click="XYchecked = false" />
                  我已阅读并同意《用户协议》和《隐私政策》
                </div>
                <!-- <div class="args-item">
                  <img src="@/assets/images/login/check.png" v-show="!argsStatus2" alt="" @click="argsStatus2 = true" />
                  <img src="@/assets/images/login/checked.png" v-show="argsStatus2" alt="" @click="argsStatus2 = false" />
                  确认已满18岁，盲盒娱乐理性消费，请勿上头
                </div> -->
              </div>
              <el-form-item>
                <div class="btn-box">
                  <div class="btn" @click="PostRegister">注册</div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 箱子遮罩层 -->
    <van-overlay
      :lock-scroll="false"
      @mousewheel.prevent.native="() => {}"
      :show="XZshow">
      <div class="wrapper">
        <div class="block">
          <div class="block-T">
            <p>{{ Box_Name }}</p>
            <span @click="CloseXZshow">×</span>
          </div>
          <div class="Box-List">
            <div
              class="Box-List-item"
              v-for="(item, index) in JiangPin_Arr"
              :key="index">
              <div class="pic">
                <div class="zhao">
                  <img :src="item.lv_bg_image" alt="" />
                </div>
                <img :src="item.cover" alt="" />
              </div>
              <div class="name">{{ item.name | GuoLv }}</div>
              <div class="money">
                <img src="@/assets/images/PublicImg/JinBi.png" alt="" /><span>{{
                  item.bean
                }}</span>
              </div>
            </div>
          </div>
          <div class="boxbtn">
            <p @click="CloseXZshow">确定</p>
          </div>
        </div>
      </div>
    </van-overlay>

    <!-- 排行 -->
    <Rank v-show="true" />
    <!-- 高光时刻弹窗 -->
    <PopupIndex />
  </div>
</template>

<script>
import {
  Register,
  PostSendSms,
  SignIn,
  PersonalInfo,
  Arena_Box_List,
  Info,
} from "@/network/api.js";
import { dropList } from "./index";
import { mapState, mapMutations, mapActions } from "vuex";
import Swiper from "@/components/common/NavHeader/Swiper.vue";
import Rank from "@/views/Rank/index.vue";
import PopupIndex from "@/components/Highlight/popupIndex.vue";
export default {
  name: "NavHeader",
  data() {
    return {
      argsStatus1: false,
      argsStatus2: false,
      W_BL: false,
      Box_Name: "",
      time: 60 * 1000,
      VerificationShow: false,
      Box_Arr: [],
      checked: true,
      XYchecked: false,
      JiangPin_Arr: [],
      site_bulletin: "",
      Box_Arr_Show: false,
      XZshow: false,
      RegisterForm: {
        name: "",
        mobile: "",
        password: "",
        password1: "",
        verify: "",
        invite: "",
      },

      SignInForm: {
        mobile: "",
        password: "",
        verify: "",
      },
      SuiJiNum: 0,
      info: {},
      // 验证码密码切换
      PWCode: true,
      dropList: dropList,
      currentIndex: 0,
      noticeBarStatus: true,
    };
  },
  created() {
    this.MyInfo();
    // this.GetInfo();
    if (window.screen.width < 750) {
      this.W_BL = true;
    } else {
      this.W_BL = false;
    }
    if (
      this.$route.path == "/BoxFight" ||
      this.$route.path == "/vsbox" ||
      this.$route.path == "/CreateAConfrontation"
    ) {
      this.Box_Arr_Show = true;
    }
    const array = window.location.search?.split("=");
    if (array.length > 1) {
      this.RegisterForm.invite = array[1];
    }

    this.PanDuanDengLu();
    this.Get_Arena_Box_List();
    this.GetInfo();
    this.GetInfo2();
    // this.Duuser()
  },

  filters: {
    GuoLv(str) {
      return str.length > 12 ? str.substr(0, 10) + "..." : str;
    },
    STY(val) {
      if (val == true) {
        return "width:80%;";
      } else {
        return "width:5rem;";
      }
    },
  },
  methods: {
    ...mapActions({
      MyInfo: "Refresh",
    }),
    // dropItemClick(i) {
    //   this.currentIndex = i;
    //   switch (i) {
    //     case 0:
    //       this.jump("/classicsBox");
    //       break;
    //     case 1:
    //       this.jump("/Lucky");
    //       break;
    //     case 2:
    //       this.jump("/Battle");
    //       break;
    //     case 3:
    //       this.jump("/Roll");
    //       break;

    //     default:
    //       break;
    //   }
    // },
    // 去分享验证
    goabout() {
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
        this.PostSignInShow();
      } else {
        this.jump("/shareReward");
      }
    },
    // 去背包验证
    goWarehouse() {
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
        this.PostSignInShow();
      } else {
        this.jump("/Warehouse");
      }
    },

    gopay() {
      // 进入支付页面判断是否登录
      if (!this.user.id) {
        this.$message({
          message: "未登录，请登录后操作",
          type: "error",
        });
        this.PostSignInShow();
      } else {
        this.jump("/BagRecharge");
      }
    },
    //初次进入判断是否记住登录
    PanDuanDengLu() {
      if (localStorage.getItem("token")) {
        this.GetPersonalInfo();
      } else {
        if (localStorage.getItem("token")) {
          localStorage.setItem("token", localStorage.getItem("token"));
          this.GetPersonalInfo();
        } else {
          this.GetIndividualsShow(false);
        }
      }
      // if (sessionStorage.getItem("token")) {
      //   this.GetPersonalInfo();
      // } else {
      //   if (localStorage.getItem("token")) {
      //     sessionStorage.setItem("token", localStorage.getItem("token"));
      //     this.GetPersonalInfo();
      //   } else {
      //     this.GetIndividualsShow(false);
      //   }
      // }
    },

    //站点信息
    GetInfo2() {
      Info().then((res) => {
        this.info = res.data.data;
        localStorage.setItem("static_file_url", res.data.data.static_file_url);
        localStorage.setItem("website_icp", res.data.data.website_icp);
        this.GetSuiJi();
      });
    },

    GetInfo() {
      Info().then((res) => {
        this.site_bulletin = res.data.data.site_bulletin;
      });
    },

    GetSuiJi() {
      this.SuiJiNum =
        Math.floor(Math.random() * (50 - Number(this.info.user_number))) +
        Number(this.info.user_number);
    },

    CloseXZshow() {
      this.XZshow = false;
    },

    //暂未开放
    WuKaiFang() {
      this.$message({
        message: "暂未开放,敬请期待!",
        type: "error",
      });
    },

    //查看箱子内容
    ShowNeiRong(item) {
      this.JiangPin_Arr = item.box_awards;
      this.Box_Name = item.name;
      this.XZshow = true;
    },

    //获取宝箱列表
    Get_Arena_Box_List() {
      Arena_Box_List().then((res) => {
        this.Box_Arr = res.data.data;
      });
    },

    //路由跳转
    jump(str) {
      this.$router
        .push({
          path: str,
        })
        .catch((err) => {
          this.$router.go(0);
        });
    },

    //修改密码
    GetChangePassword() {
      // this.$router.push("/ChangePassword");
      this.GetSignInShow(true);
    },

    //注册操作
    PostRegister() {
      if (this.XYchecked) {
        if (this.RegisterForm.password !== this.RegisterForm.password1) {
          this.$message({
            message: "两次输入密码不一致，请检查后重新输入",
            type: "error",
          });
          return false;
        }
        Register(
          this.RegisterForm.mobile,
          this.RegisterForm.password,
          this.RegisterForm.name,
          this.RegisterForm.verify,
          this.RegisterForm.invite
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "注册成功",
              type: "success",
            });
            this.GetRegisterShow(false);
            this.EmptyForm();
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请先勾选用户协议",
          type: "error",
        });
      }
    },

    //发送手机号验证码
    GetYanZhengMa(num) {
      if (this.RegisterForm.mobile || this.SignInForm.mobile) {
        this.VerificationShow = true;
        this.$refs.countDown.start();
        PostSendSms(
          this.RegisterForm.mobile || this.SignInForm.mobile,
          num
        ).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },
    //注册获取验证码
    GetYanZhengMa2(num) {
      if (this.RegisterForm.mobile) {
        // this.VerificationShow = true;
        // this.$refs.countDown.start();
        PostSendSms(this.RegisterForm.mobile, num).then((res) => {
          if (res.data.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
          } else {
            this.$message({
              message: res.data.message,
              type: "error",
            });
          }
        });
      } else {
        this.$message({
          message: "请输入手机号",
          type: "error",
        });
      }
    },

    finish() {
      //重置倒计时
      this.VerificationShow = false;
      this.$refs.countDown.reset();
    },

    //取消注册
    RegisterQX() {
      this.GetRegisterShow(false);
      this.EmptyForm();
    },

    //登录
    PostSignInShow() {
      this.GetSignInShow(true);
    },
    //注册
    PostRegisterInShow() {
      this.GetRegisterShow(true);
    },

    //登录操作
    PostSignIn() {
      if (!this.argsStatus1 || !this.argsStatus2) {
        this.$message.error("请先勾选用户协议!");
        return;
      }
      SignIn(
        this.SignInForm.mobile,
        this.SignInForm.password,
        this.SignInForm.verify
      ).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "登录成功",
            type: "success",
          });
          localStorage.setItem(
            "token",
            "Bearer" + " " + res.data.data.access_token
          );
          // if (this.checked == true) {
          //   localStorage.setItem(
          //     "token",
          //     "Bearer" + " " + res.data.data.access_token
          //   );
          //   sessionStorage.setItem(
          //     "token",
          //     "Bearer" + " " + res.data.data.access_token
          //   );
          // } else {
          //   sessionStorage.setItem(
          //     "token",
          //     "Bearer" + " " + res.data.data.access_token
          //   );
          // }
          this.GetSignInShow(false);
          this.GetPersonalInfo();
          this.EmptySignInForm();
          this.MyInfo();
        } else {
          this.$message({
            message: res.data.message,
            type: "error",
          });
        }
      });
    },

    //个人信息
    GetPersonalInfo() {
      PersonalInfo().then((res) => {
        localStorage.setItem("boxUserInfo", JSON.stringify(res.data.data));
        this.GetIndividualsShow(true);
      });
    },

    //取消登录
    SignInQX() {
      this.EmptySignInForm();
      this.GetSignInShow(false);
    },
    //取消注册
    SignInQX2() {
      this.EmptySignInForm();
      this.GetRegisterShow(false);
    },

    //个人下拉菜单
    handleCommand(command) {
      switch (command) {
        case "out":
          this.SignOut();
          break;
        default:
          this.$router.push(command);
      }
    },

    //退出
    SignOut() {
      this.GetIndividualsShow(false);
      this.Leave();
    },

    //清空注册表单
    EmptyForm() {
      // this.RegisterForm.email = "";
      this.RegisterForm.name = "";
      this.RegisterForm.mobile = "";
      this.RegisterForm.password = "";
      this.RegisterForm.password1 = "";
      this.RegisterForm.verify = "";
      this.RegisterForm.invite = "";
    },

    //清空登录表单
    EmptySignInForm() {
      // this.SignInForm.email = "";
      this.SignInForm.pass = "";
    },

    //路由跳转充值
    ToRecharge() {
      this.$router.push("/Recharge");
    },

    ...mapMutations([
      "User",
      "GetIndividualsShow",
      "Leave",
      "GetRegisterShow",
      "GetSignInShow",
    ]),
  },

  computed: {
    ...mapState(["user", "IndividualsShow", "RegisterShow", "SignInShow"]),
  },

  watch: {
    RegisterShow() {
      this.EmptyForm();
    },
    Email_Name(val) {
      this.Email_Name = val;
    },

    $route(to, from) {
      if (
        to.path == "/BoxFight" ||
        to.path == "/vsbox" ||
        to.path == "/CreateAConfrontation"
      ) {
        this.Box_Arr_Show = true;
      } else {
        this.Box_Arr_Show = false;
      }
    },
  },

  components: {
    Swiper,
    Rank,
    PopupIndex,
  },
};
</script>

<style lang="scss">
#NavHeader {
  width: 100%;
  background: rgba(120, 120, 120, 0.6);
  position: relative;

  .XiangZi_Box {
    width: 100%;
    display: flex;

    .XiangZi_item {
      width: 1.92rem;
      height: 2.3rem;
      background: rgba(25, 25, 25, 0.6);
      border: 1px solid #2b2b2b;
      margin-left: -1px;
      cursor: pointer;
      text-align: center;
      position: relative;
      box-sizing: border-box;

      .pic {
        width: 1.6rem;
        height: 1.6rem;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          vertical-align: middle;
          max-width: 80%;
          max-height: 80%;
        }
      }

      span {
        img {
          width: 0.2rem;
          height: 0.2rem;
          vertical-align: middle;
        }

        font-size: 0.14rem;
        color: #ffd300;
      }

      p {
        margin-top: 0.08rem;
        font-size: 0.14rem;
      }

      &:hover {
        border-color: #ffd300;
        z-index: 5;

        p {
          color: #ffd300;
        }
      }
    }
  }

  .nav {
    width: 100%;
    height: 0.9rem;
    // background: linear-gradient(#0d2950, #091f3e, #05152b, #031226, #031226);
    // background: #0b2549;
    background: #041c33;
    // position: fixed;
    // left: 0;
    // top: 0;
    // z-index: 99;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    .Nav_Left {
      display: flex;
      align-items: center;

      .logo {
        // width: 2rem;
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-right: 0.3rem;

        img {
          width: 1.6rem;
        }
      }

      .nav-box {
        box-sizing: border-box;
        display: flex;
        align-items: center;

        .item {
          width: 1.3rem;
          height: 0.9rem;
          line-height: 0.9rem;
          box-sizing: border-box;
          text-align: center;
          cursor: pointer;
          position: relative;
          margin-right: 0.2rem;
          position: relative;
          display: flex;
          justify-content: center;

          .nav-item-l {
            display: flex;
            flex-direction: column;
            height: 100%;

            span {
              height: 22%;
              font-size: 0.2rem;
              font-weight: 600;
            }

            i {
              font-size: 0.12rem;
              color: #485364;
              height: 78%;
              white-space: nowrap;
            }
          }

          .nav-item-r {
            width: 0.06rem;
            height: 0.06rem;
            background: #fff;
            border-radius: 50%;
            align-self: center;
            margin-left: 0.2rem;
          }

          .line {
            display: block;
            width: 100%;
            height: 0.04rem;
            position: absolute;
            background: #7581eb;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            opacity: 0;
          }

          &:hover {
            background: rgba($color: #fff, $alpha: 0.03);
          }

          &.active {
            background: #232749;

            .nav-item-l {
              span {
                color: #ff6600;
                font-weight: 600;
              }
            }

            .nav-item-r {
              background: #ff6600;
              display: none;
            }

            i {
              opacity: 1;
            }
          }

          .drop {
            display: none;
            position: absolute;
            width: 1.95rem;
            height: 2.18rem;
            bottom: -2.06rem;
            background: url(../../../assets/images/NavHeader/drop.png) no-repeat;
            background-size: contain;
            z-index: 10;
            padding: 0.2rem;
            box-sizing: border-box;

            .drop-item {
              // height: 0.35rem;
              font-size: 0.14rem;
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              box-sizing: border-box;
              padding-bottom: 0.12rem;
              margin-top: 0.03rem;

              // .lot {
              //   width: 3px;
              //   height: 3px;
              //   background: #666666;
              //   border-radius: 50%;
              //   margin-right: 0.1rem;
              // }
              img {
                display: block;
                margin-right: 0.1rem;
              }

              div {
                // width: 80px;
                letter-spacing: 0.05rem;
                height: 0.35rem;
                line-height: 0.35rem;
                color: #666666;
                font-size: 0.16rem;
              }

              &:hover {
                background: url(../../../assets/images/NavHeader/drop-line-ac.png)
                  no-repeat;
                background-size: contain;
                background-position: bottom;

                // .lot {
                //   background: #ead18f;
                // }

                div {
                  color: #ead18f;
                }
              }
            }

            .drop-item-bl {
              background: url(../../../assets/images/NavHeader/drop-line.png)
                no-repeat;
              background-size: contain;
              background-position: bottom;
            }

            .drop-item-bl-ac {
              background: url(../../../assets/images/NavHeader/drop-line-ac.png)
                no-repeat;
              background-size: contain;
              background-position: bottom;
              color: #ead18f;

              // .lot {
              //   background: #ead18f;
              // }

              div {
                color: #ead18f;
              }
            }
          }
        }

        .gameClass {
          &:hover {
            .drop {
              display: block;
            }
          }
        }

        .nav-pay-box {
          display: flex;
          width: 2.2rem;
          border: 1px solid #3582fb;
          height: 0.5rem;
          position: relative;
          background: linear-gradient(#0d1b2b, #0a121b, #1d2e43);
          cursor: pointer;

          .item-pay,
          .item-package {
            // font-size: 0.17rem;
            font-size: 0.2rem;
          }

          &::before,
          &::after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.15rem 0.15rem 0 0;
            border-color: #007bff transparent transparent transparent;
          }

          &::before {
            right: 0;
            bottom: 0;
            transform: rotate(180deg);
          }

          div {
            box-sizing: border-box;
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          i {
            width: 0.02rem;
            height: 0.3rem;
            background: #354663;
            align-self: center;
          }
        }
      }
    }

    .personal {
      height: 100%;
      margin-left: 0.2rem;

      .per {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
        padding-right: 30px;
        background-size: 100% 100%;

        p {
          width: 1.28rem;
          height: 100%;
          text-align: center;
          color: #75cd66;
          font-size: 0.18rem;
          font-weight: bold;
          border-left: 1px solid rgba($color: #fff, $alpha: 0.08);
          margin: 0;

          img {
            display: block;
            margin: 0.2rem auto 0.05rem;
            width: 24px;
            height: 24px;
          }
        }

        .user {
          width: 100%;
          height: 100%;
          padding-left: 30px;
          display: flex;
          align-items: center;

          .user-img {
            display: block;
            width: 0.6rem;
            height: 0.6rem;
            border-radius: 50%;
            border: 1px solid #ffba2e;
            margin: 0.05rem 0.1rem;
          }

          span {
            font-size: 0.14rem;
          }
        }
      }

      .Sign {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .Sign-item {
          // width: 0.4rem;
          height: 0.3rem;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0.04rem;
          text-align: center;
          line-height: 0.3rem;
          color: #fff;

          p {
            font-size: 0.16rem;
          }
        }
      }
    }
  }

  .SwiperBox-text {
    position: absolute;
    top: 2.65rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9;
    color: #fff;
    opacity: 0.6;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .img {
      // position: absolute;
      // top: 50%;
      // bottom: -0.06rem;
      // left: 2%;
      // z-index: 2;
      width: 0.15rem;
      height: 0.15rem;
      // margin-left: 0.15rem;
      // transform: translateY(-60%);
    }

    .notice-bar {
      margin: -0.3rem 0;
      font-size: 0.14rem;
      border-radius: 1rem;
      border: 0.05rem solid #1e3548;
      // background: #171717;
      padding-left: 0.6rem;
      flex-grow: 1;
      background: url("../../../assets/images/NavHeader/11.png") no-repeat
        center;
      background-size: auto;
      background-position-x: 0.2rem;
    }
  }

  .data_info {
    width: 100%;
    padding: 0 0.6rem 0.2rem;
    box-sizing: border-box;
    height: 0.6rem;
    display: flex;
    align-items: center;

    .data_info_item {
      display: flex;
      align-items: center;
      height: 0.6rem;
      margin-right: 0.2rem;

      img {
        height: 0.4rem;
        margin-right: 0.05rem;
      }

      p {
        font-size: 0.22rem;
        color: #a99bc5;

        span {
          color: #ea4391;
          margin-right: 0.04rem;
        }
      }
    }
  }

  .Wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;

    .out {
      display: flex;
      width: 8.01rem;
      // height: 8.06rem;
      background: url(../../../assets/images/login/login-bg.png);
      background-size: 100% 100%;
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      overflow: hidden;

      .close-box {
        position: absolute;
        top: 0;
        right: 0;
        width: 1.9rem;
        height: 0.39rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url(../../../assets/images/login/close-box.png);
        background-size: 100% 100%;
        cursor: pointer;

        img {
          width: 0.29rem;
          height: 0.29rem;
        }
      }

      .login-title {
        margin-top: 0.34rem;
        margin-bottom: 0.23rem;
      }
    }

    .DZ-box {
      color: #fff;
      position: relative;
      width: 100%;
      margin-top: 0.24rem;

      /*border-top: 3px solid #7581EB;*/
      .active-login {
        display: flex;
        width: 100%;
        height: 0.54rem;
        background: url(../../../assets/images/login/pass-login.png) no-repeat;
        background-size: 100% 100%;
        width: 5.52rem;
        margin: 0 auto 0.53rem;

        span {
          width: 50%;
          height: 100%;
          font-size: 0.3rem;
          color: #fff;
          font-weight: 400;
          text-align: center;
          line-height: 0.54rem;

          &:nth-child(2) {
            color: #8f9195;
          }
        }
      }

      .active-login-ac {
        background: url(../../../assets/images/login/code.png) no-repeat;
        background-size: 100% 100%;

        span {
          &:nth-child(1) {
            color: #8f9195;
          }

          &:nth-child(2) {
            color: #fff;
          }
        }
      }

      .input-wrap {
        display: flex;
        align-items: center;
        background: rgba(0, 0, 51, 0.36);
        border: 0.01rem solid #3399ff;
        box-shadow: 0px 0px 1px 1px #3399ff;
        box-sizing: border-box;
        padding-left: 0.1rem;
        height: 0.57rem;

        .phone-icon {
          width: 0.3rem;
          height: 0.32rem;
        }
      }

      .Steam {
        width: 100%;
        padding-left: 0.9rem;
        text-align: center;
        box-sizing: border-box;
        margin-bottom: 0.2rem;

        p {
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #3399ff;
        }

        .img_box {
          margin: 0.2rem auto 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0066cc;

          img {
            width: 0.4rem;
            height: 0.4rem;
            margin-right: 0.12rem;
          }

          cursor: pointer;
        }
      }

      .login-tips {
        font-size: 0.17rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #0066cc;
        text-align: center;

        p {
          width: fit-content;
          margin-left: 2.4rem;
          line-height: 1.5;
        }
      }

      .el-form {
        width: 90%;
        margin-top: 0.2rem;

        .remember_box {
          width: 100%;
          height: 0.2rem;
          display: flex;
          padding-left: 0.9rem;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          margin-bottom: 0.2rem;

          p {
            line-height: 0;
            margin-top: 0;
            font-size: 0.14rem;
            cursor: pointer;

            &:hover {
              color: red;
            }
          }

          .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #ffd322;
          }

          .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox__input.is-indeterminate .el-checkbox__inner {
            border-color: #ffd322;
            background: #ffd322;
          }

          .el-checkbox {
            color: #000;
            font-size: 0.14rem;
          }
        }

        .el-form-item__label {
          color: #fff;
          font-size: 0.14rem;
        }

        // .el-input {
        //   // height: 0.55rem;
        //   .el-input__inner {
        //     background: #002154 !important;
        //     border-radius: 0;
        //     border: none;
        //     color: #fff;
        //     height: 0.55rem;
        //   }

        //   input:-webkit-autofill,
        //   textarea:-webkit-autofill,
        //   select:-webkit-autofill {
        //     -webkit-text-fill-color: #fff;
        //     box-shadow: 0 0 0px 50px #002154 inset !important;
        //   }
        // }
      }

      .YZM-box {
        .el-form-item__content {
          display: flex;
          align-items: center;
          line-height: unset;
          .el-input {
            width: 70%;
          }

          .YZM {
            position: absolute;
            top: -0rem;
            right: 0;
            width: 2.5rem;
            height: 0.66rem;
            transform: translateY(-0.05rem);
            margin-left: 0.3rem;
            font-size: 0.26rem;
            line-height: 0.66rem;
            text-align: center;
            background: url(../../../assets/images/about/reward/btn.png)
              no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
            color: #fff;
          }

          .DJS {
            position: absolute;
            top: -0rem;
            right: 0;
            width: 2.2rem;
            height: 0.66rem;
            transform: translateY(-0.05rem);
            width: 2.2rem;
            height: 0.66rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0.3rem;
            background: url(../../../assets/images/about/reward/btn.png)
              no-repeat;
            background-size: 100% 100%;

            .XXX {
              height: 0.38rem;
              font-size: 0.26rem;
              margin-right: 0.05rem;
              box-sizing: border-box;
              margin-bottom: 0.02rem;
              line-height: 0.38rem;
            }

            .CLR {
              font-size: 0.26rem;
              color: #fff;
              line-height: 0.66rem;
            }
          }
        }
      }

      .code {
        width: 71%;
        position: relative;
        padding-right: 0.2rem;
      }

      .args {
        width: 100%;
        margin: 0 auto;
        padding-left: 2rem;

        .args-item {
          display: flex;
          align-items: center;
          margin-bottom: 0.24rem;
          font-size: 0.17rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #0066cc;

          img {
            width: 0.3rem;
            margin-right: 0.03rem;
            cursor: pointer;
          }
        }
      }

      .btn-box {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0.17rem;

        .btn {
          width: 2.22rem;
          height: 0.67rem;
          background: url(../../../assets/images/login/btn.png) no-repeat;
          background-size: 100% 100%;
          color: #000;
          text-align: center;
          line-height: 0.67rem;
          margin: 0 0.1rem;
          border-radius: 0.04rem;
          cursor: pointer;
          font-size: 0.26rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #cccccc;
        }
      }

      .XY_box {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;

        .el-checkbox__label {
          color: #ffd322;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          border-color: #ffd322;
          background: #ffd322;
        }
      }
    }
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .block {
    width: 8rem;
    height: 6.7rem;
    background-color: #212529;
    border-radius: 0.06rem;
    padding: 0.2rem;
    box-sizing: border-box;

    .block-T {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      p {
        font-size: 0.24rem;
        color: #fff;
      }

      span {
        font-size: 0.28rem;
        color: rgba($color: #ffffff, $alpha: 0.6);
        cursor: pointer;
      }
    }

    .Box-List {
      width: 100%;
      height: 5rem;
      margin: 0.2rem 0 0.4rem;
      overflow: auto;
      display: flex;
      flex-wrap: wrap;

      .Box-List-item {
        width: 1.8rem;
        height: 2.3rem;
        position: relative;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 0.04rem;
        margin-right: 0.1rem;
        margin-bottom: 0.1rem;
        cursor: pointer;
        overflow: hidden;

        &:nth-child(4n) {
          margin-right: 0;
        }

        &:hover {
          .ceng {
            opacity: 1;
          }
        }

        .pic {
          width: 1.8rem;
          height: 1.5rem;
          margin: 0.1rem auto 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .zhao {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          img {
            max-width: 100%;
            max-height: 100%;
            position: relative;
            z-index: 10;
          }
        }

        .name {
          width: 100%;
          text-align: center;
          color: #9e9e9e;
          font-size: 0.14rem;
        }

        .money {
          width: 100%;
          margin-top: 0.1rem;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 0.2rem;
            height: 0.2rem;
            vertical-align: middle;
          }

          span {
            font-size: 0.14rem;
            color: #ffd300;
          }
        }
      }
    }

    .Box-List::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 4px;
      scrollbar-arrow-color: red;
    }

    .Box-List::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.6);
      scrollbar-arrow-color: red;
    }

    .Box-List::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }

    .boxbtn {
      width: 100%;
      height: 0.35rem;
      display: flex;
      justify-content: flex-end;

      p {
        width: 0.7rem;
        height: 0.35rem;
        background: red;
        font-size: 0.14rem;
        color: #fff;
        text-align: center;
        line-height: 0.35rem;
        cursor: pointer;
      }
    }
  }
}

.NavHeader-bg {
  margin-top: 0.9rem;
}
</style>
