export const dropList = [
    {
        name: "经典盲盒",
        icon: '@/assets/images/NavHeader/openbox.png'
    },
    {
        name: "幸运饰品",
        icon: '@/assets/images/NavHeader/lucky.png'
    },
    {
        name: "虚拟对战",
        icon: '@/assets/images/NavHeader/battle.png'
    },
    {
        name: "ROLL房",
        icon: '@/assets/images/NavHeader/rollicon.png'
    },
];
