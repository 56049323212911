<template>
  <div id="Index">
    <MeanHeader
      v-if="
        ![
          'About',
          'Warehouse',
          'BagRecharge',
          'shareReward',
          'Tipspage',
        ].includes($route.name)
      " />
    <!-- <swiper/> -->
    <!-- <nav-header></nav-header> -->
    <div class="out-bj">
      <!-- <keep-alive> -->
      <router-view id="output"></router-view>
      <!-- </keep-alive> -->
    </div>

    <div
      v-show="showScrollTopBtn"
      @click="scrollTopOnClick"
      class="scroll-top"></div>
    <!-- <public-footer></public-footer> -->
    <div id="footer"></div>
  </div>
</template>

<script>
// 进入的首页
import NavHeader from "@/components/common/NavHeader/NavHeader.vue";
import MeanHeader from "@/components/common/MeanHeader.vue";
import Swiper from "@/components/common/NavHeader/Swiper.vue";
import { mapActions } from "vuex";

export default {
  name: "Index",
  data() {
    return {
      showScrollTopBtn: false,
    };
  },
  //activated
  created() {
    this.Init();
  },
  methods: {
    ...mapActions({
      MyInfo: "Refresh",
    }),
    Init() {
      this.MyInfo();
      window.addEventListener("scroll", () => {
        this.showScrollTopBtn = false;
        if (document.documentElement.scrollTop > 800) {
          this.showScrollTopBtn = true;
        }
      });
    },
    scrollTopOnClick() {
      const timeTop = setInterval(() => {
        let top = document.documentElement?.scrollTop || 0;
        document.documentElement.scrollTop = top -= 50; //一次减50往上滑动
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 5); //定时调用函数使其更顺滑
    },
  },

  components: {
    NavHeader,
    // PublicFooter,
    MeanHeader,
    Swiper,
  },
};
</script>

<style lang="scss">
#Index {
  // background: url(../../assets/images/public/Index_bj.png);
  // background-attachment: fixed;
  // background-position: top;
  // background-size: contain;
  // overflow-x: hidden;
  // background: #020715;
  height: 100vh;
  overflow-y: auto;

  .out-bj {
    width: 100%;
  }
  .scroll-top {
    position: fixed;
    right: 0.1rem;
    bottom: 1rem;
    width: 0.34rem;
    height: 0.34rem;
    background: url("../../assets/newImages/mb-top.png") no-repeat;
    background-size: 100% 100%;
  }

  .el-backtop {
    background: #4854c9;

    i {
      color: #fff;
    }
  }

  #output {
    width: 3.75rem;
    // padding-bottom: 0.075rem;
    margin: 0 auto;
  }

  .Tips {
    text-align: center;
    color: #2a9fd6;
    font-size: 0.14rem;
    line-height: 0.5rem;
    cursor: pointer;

    &:hover {
      color: #2fc8ff;
    }
  }
}
</style>
